import React, { RefObject } from 'react'
import styled from '@emotion/styled'

import PlaylistItem from './PlaylistItem'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PlaylistMobileItem from './PlaylistMobileItem'

import 'swiper/css/pagination'

const Container = styled('div')`
  width: 100%;
  max-width: 950px;
  padding: 20px 16px 20px;
  margin: 0 auto;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
`

const Item = styled('div')`
  width: calc(33% - 8px);
`
const SwiperWrapper = styled('div')`
  margin-left: -16px;
  margin-right: -16px;

  .swiper-pagination {
    bottom: -10px !important;
    .swiper-pagination-bullet {
      background-color: #ffdd60;
    }
    .swiper-pagination-bullet-active {
      background-color: #ffdd60;
    }
  }
`

const PlaylistMobile = ({ fables, customRef }: any) => {
  return (
    <Container>
      <SwiperWrapper>
        <Swiper
          modules={[Pagination]}
          spaceBetween={8}
          slidesPerView={1}
          // navigation
          pagination={{
            clickable: true,
            dynamicBullets: true
          }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 22,
            paddingTop: 22
          }}
          breakpoints={{
            540: {
              slidesPerView: 2
            },
            720: {
              slidesPerView: 3
            },
            900: {
              slidesPerView: 3
            }
          }}
        >
          {fables.map(({ node }: { node: any }) => {
            return (
              <SwiperSlide key={node.id}>
                <PlaylistMobileItem
                  fable={node.frontmatter}
                  customRef={customRef}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperWrapper>
    </Container>
  )
}

export default PlaylistMobile
