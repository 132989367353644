import React, { RefObject, useContext, useState } from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'
import { BgImage } from 'gbimage-bridge'
import TweenOne from 'rc-tween-one'
import Play from '../../svg/Play'
import PlayerContext, { FableProps } from '../../context'
import PlayerIframe from '../Player/PlayerIframe'

const Container = styled('div')`
  overflow: hidden;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  position: relative;
`
const Inner = styled('div')`
  padding: 10px;
  display: flex;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.6) -3.33%,
    rgba(0, 0, 0, 0) 22.29%,
    rgba(0, 0, 0, 0.6) 115%
  );
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  position: relative;
  z-index: 1;
`

const Header = styled('div')`
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: auto;
  margin-top: 10px;
`
const Nation = styled('div')`
  font-size: 16px;
  color: #ffffff;
`

interface PlaylistItemProps {
  fable: FableProps
  customRef: RefObject<HTMLDivElement> | null
}
const StyledBackground = styled(BgImage)`
  &::before,
  &::after {
    filter: invert('0%');
  }
`

const PlaylistMobileItem = ({ fable, customRef }: PlaylistItemProps) => {
  const [state, setState] = useState<{
    moment: number | null
    paused: boolean
    reverse: boolean
  }>({ moment: null, paused: true, reverse: false })
  const { currentFable, setCurrentFable } = useContext(PlayerContext)
  const imageData = fable.background
  const { attachments, title, nation } = fable
  const cover = getImage(imageData)

  return (
    <Container>
      <StyledBackground Tag='div' image={cover}>
        <Inner>
          <Nation>{nation}</Nation>
          <Header>{title}</Header>
          {attachments.length > 0 && attachments[0] && (
            <audio style={{ width: '100%' }} controls>
              <source src={attachments[0].publicURL} type='audio/mpeg' />
            </audio>
          )}
        </Inner>
      </StyledBackground>
    </Container>
  )
}

export default PlaylistMobileItem
